<template>
<!--  <div>
    <div class="top-box">
      <van-image :src="require('@/assets/images/pay/bg.png')" width="100%" height="170px"></van-image>
      <div class="top-time-box">
        <div class="wait-box">
          <van-image :src="require('@/assets/images/pay/wait.png')" width="14px" height="14px"></van-image>
          <div class="wait-z">等待付款</div>
        </div>
        <div class="time-box"><span>需付款：￥99.00  倒计时：13小时24分钟</span></div>
        <div class="pay-btn">
          去支付
        </div>
      </div>
      <div class="rectangle"></div>

    </div>
    <div class="address-box">
      <div class="name-box">
        <span>李三</span>
        <span class="ml-5">18560625252</span>
      </div>
      <div class="address-detail mt-1">地址：山东省青岛市城阳区首创空港</div>
    </div>

    <div class="product-box">
      <div class="shop-box">
        <van-image :src="require('@/assets/images/order/shop.png')" width="20px" height="17px"></van-image>
        <div class="shop-name">稚优泉专卖店</div>
      </div>
      <van-card
          num="2"
          price="2.00"
          desc="描述信息"
          title="商品标题"
          thumb="https://t7.baidu.com/it/u=4198287529,2774471735&fm=193&f=GIF"
      />
    </div>

    <div class="order-info-box">
      <div class="order-no-box">
        <div>订单编号：&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 493739853953323</div>
        <div><van-image :src="require('@/assets/images/pay/copy.png')" width="15px" height="15px"></van-image></div>
      </div>
      <div class="mt-3">下单时间：&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 2021-02-02 14:00:00</div>
      <div class="mt-3">支付方式：&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 未支付</div>
      <div class="mt-3">配送方式：&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 普通快递</div>
      <div class="line mt-3"></div>
      <div class="total-box mt-3">
        <div>商品总额：</div>
        <div>￥99.90</div>
      </div>
      <div class="total-box mt-3">
        <div>运费：</div>
        <div>+￥0.00</div>
      </div>
      <div class="pay-money mt-3">
        <div class="money-title">需付款：</div>
        <div class="money">￥99.90</div>
      </div>
    </div>
    <div class="bottom-box">
      <div class="go-pay">去支付</div>
    </div>
  </div>-->
  <div class="wrap">
    <van-row type="flex">
      <van-col span="24">
        <div class="status-wrap">
<!--            <div class="status-bg">
              <van-image :src="require('@/assets/images/pay/bg.png')" width="100%" fit="contain"></van-image>
            </div>-->
          <div class="col status">
            <van-icon class="mr-3" :name="require('@/assets/images/pay/wait.png')" size="14"/>
            等待付款
          </div>
          <div class="col pay-info">
            <span>需付款：￥{{orderInfo.payAmount|formatPrice}}</span>
            <span>倒计时：<van-count-down :time="latestPayTime" format="HH 时 mm 分 ss 秒" /></span>
          </div>
          <div class="col pay-btn">
            <van-button size="small" block @click="preUnifiedorder">去支付</van-button>
          </div>
        </div>
      </van-col>
      <van-col span="24" v-if="receiverInfo.receiveCityId">
        <div class="address-wrap py-3">
          <van-cell centerv-show="receiverInfo.receiveCityId">
            <template #title>
              <div class="address">
                <div class="user">
                  <span>{{ receiverInfo.receiverName }}</span><span>{{ receiverInfo.receiverPhone }}</span>
                </div>
                <div class="address-detail">
                  <div class="tag">地址：</div>
                  <div class="detail">
                    {{ receiverInfo.receiverProvince + receiverInfo.receiverCity + receiverInfo.receiverRegion + receiverInfo.receiverDetailAddress }}
                  </div>
                </div>
              </div>
            </template>
          </van-cell>
        </div>
      </van-col>
      <van-col span="24">
        <div class="order-item-wrap mt-3">
          <div class="order-merchant mt-3" >
            <!--            v-show="item.merchantId && item.merchantName"-->
            <div class="merchant" v-if="goods.merchantId && goods.merchant && goods.merchant.shortName">
              <van-icon :name="require('@/assets/images/order/shop.png')" size="20"/>
              {{goods.merchant.shortName}}
            </div>
            <div class="card-wrap">
              <van-card
                  :price="Number(goods.productPrice).toFixed(2)"
                  :desc="productAttr(goods.productAttr)"
                  :title="goods.productName"
                  :thumb="goods.productPic"
              />
              <div class="card-num">
                x{{goods.productQuantity}}
              </div>
            </div>

          </div>
        </div>
      </van-col>
      <van-col span="24">
        <div class="order-info-wrap mt-3">
          <div class="item">
            <div class="title">订单编号:</div>
            <div class="content">{{orderInfo.orderSn}}</div>
          </div>
          <div class="item">
            <div class="title">下单时间:</div>
            <div class="content">{{orderInfo.createdAt|filterCreateTime}}</div>
          </div>
          <div class="item">
            <div class="title">支付方式:</div>
            <div class="content">{{orderInfo.payType|filterPayType}}</div>
          </div>
          <div class="item">
            <div class="title">配送方式:</div>
            <div class="content">{{orderInfo.delivery|filterDelivery}}</div>
          </div>
          <van-divider/>
          <div class="item total">
            <div class="title">商品总额:</div>
            <div class="content">￥{{orderInfo.totalAmount|formatPrice}}</div>
          </div>
          <div class="item total">
            <div class="title">运费:</div>
            <div class="content">￥{{orderInfo.freightAmount|formatPrice}}</div>
          </div>
          <div class="item last-total">
            <div class="title">需付款：</div>
            <div class="price">￥{{orderInfo.payAmount|formatPrice}}</div>
          </div>

        </div>
      </van-col>
      <van-col span="24">
        <div class="bottom-pay mt-3">
          <van-button color="#FF2020" size="small" @click="preUnifiedorder">去支付</van-button>
        </div>
      </van-col>
      <loading-overlay :loading="loading"/>
      <wechat/>
    </van-row>
  </div>

</template>

<script>
import Vue from 'vue'
import LoadingOverlay from "@/components/LoadingOverlay";
import {Button, Card, Cell, Col, Icon, Image, Row, Divider, CountDown, Toast} from 'vant'
import {detail, unifiedorder} from "@/api/order";
import {  format } from 'date-fns'
import {isInWeixin} from "@/utils/helper";
import wx from "weixin-js-sdk";
import Wechat  from '@/components/Wechat'

Vue.use(Image)
Vue.use(Card)
Vue.use(Row)
Vue.use(Col)
Vue.use(Icon)
Vue.use(Button)
Vue.use(Cell)
Vue.use(Divider)
Vue.use(CountDown)

export default {
  name: "payWait",
  components:{Wechat, LoadingOverlay},
  data(){
    return{
      loading: false,
      receiverInfo:{
        receiveCityId:null,
        receiverName:"",
        receiverPhone:"",
        receiverProvince:'',
        receiverCity:"",
        receiverRegion:"",
        receiverDetailAddress:""
      },
      goods:{
        merchantId:null,
        merchant:null,
        productQuantity:null,
        productPrice:null,
        productAttr:null,
        productName:null,
        productPic:null,
      },
      orderInfo:{
        status:'',
        orderSn:'',
        createdAt:'',
        totalAmount:'',
        freightAmount:'',
        payAmount:'',
        delivery:'',

        payType:'',
        latestPayTime:'',

      },
    }
  },
  computed:{
    latestPayTime(){
      if(!this.orderInfo){
        return 0
      }
      const now = new Date()
      console.log(this.orderInfo.latestPayTime , now.getTime(), this.orderInfo.latestPayTime - now.getTime())
      return this.orderInfo.latestPayTime - now.getTime()

    },
  },
  created() {
    this.fetchDetail()
  },
  filters:{
    filterDelivery(val){
      let deliveryName = ''
      switch (val) {
        case 'express':
          deliveryName = '快递运输'
          break
        case 'onSpot':
          deliveryName = '现场自提'
          break
        default:
          break
      }
      return deliveryName
    },
    formatPrice(value){
      if (!value || isNaN(value)) {
        value = 0
      }
      value = Number(value).toFixed(2);
      /*if (value === 0) {
        return 0
      }
      //console.log(value)
      if (value.split('.')[1].substring(1) === '0') {
        value = Number(value).toFixed(1);
      }*/
      return value;
    },
    filterCreateTime(date){
      if(!date){
        return ''
      }
      return format(new Date(date), 'yyyy-MM-dd HH:mm:ss')
    },
    filterPayType(payType){
      const types = [
        {lable:'未支付', value:0},
        {lable:'支付宝', value:1},
        {lable:'微信支付', value:2},
      ]

      const type = types.find(item=>{return item.value === payType})
      return type ? type.lable : ''
    }
  },
  methods:{
    goPayResult(){
      this.$router.replace({name:'payResult', params:{orderId: this.$route.params.orderId}})
    },
    doPayOrder(params){
      if(!isInWeixin()){
        Toast.fail('请在微信中打开支付');
        return false
      }
      wx.chooseWXPay({
        timestamp: params.timestamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
        nonceStr: params.nonceStr, // 支付签名随机串，不长于 32 位
        package: params.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
        signType: params.signType, // 微信支付V3的传入RSA,微信支付V2的传入格式与V2统一下单的签名格式保持一致
        paySign: params.paySign, // 支付签名
        success: (res)=> {
          console.log(res)
          // 支付成功后的回调函数
          Toast.success('支付成功');
          this.goPayResult()
        },
        fail: function () {
          Toast.fail('支付失败');
        }
      })
    },
    preUnifiedorder() {
      this.loading = true
      unifiedorder(this.$route.params.orderId).then(res => {
        this.loading = false
        if (res.code === 200) {
          console.log(res)
          const {timestamp, nonceStr, signType, sign} = res.data
          const params = {timestamp, nonceStr, package:res.data.package, signType, paySign: sign}
          this.doPayOrder(params)
        } else {
          Toast.fail(res.message ||'发起支付失败')

        }
      }, error => {
        this.loading = false
        console.log(error)
        Toast.fail('发起支付出错');
      })
    },

    fetchDetail(){
      const orderId = this.$route.params.orderId
      this.loading = true
      detail(orderId).then(res=>{
        console.log(res)
        this.loading = false
        if(res.code === 200){
          const {receiveCityId, receiverName, receiverPhone, receiverProvince, receiverCity, receiverRegion, receiverDetailAddress} = res.data
          const receiverInfo = {receiveCityId, receiverName, receiverPhone, receiverProvince, receiverCity, receiverRegion, receiverDetailAddress}
          this.receiverInfo = Object.assign(this.receiverInfo, receiverInfo)

          if(res.data.orderItemList && res.data.orderItemList.length>0){
            const {merchantId, merchant, productQuantity, productPrice, productAttr, productName, productPic} = res.data.orderItemList[0]
            const goods = {merchantId, merchant, productQuantity, productPrice, productAttr, productName, productPic}
            this.goods = Object.assign(this.goods, goods)
          }

          const {orderSn, createdAt, payType, totalAmount, freightAmount, payAmount, latestPayTime, delivery} = res.data
          const orderInfo = {orderSn, createdAt, payType, totalAmount, freightAmount, payAmount, latestPayTime, delivery}
          this.orderInfo = Object.assign(this.orderInfo, orderInfo)
        }else {
          Toast.fail(res.message||'取订单信息失败')
        }

      }, err=>{
        console.log(err)
        this.loading = false
        Toast.fail('取订单信息出错');
      })

    },
    productAttr(attrs){
      if(!attrs){
        return ''
      }
      let attrDesc = ''
      const attrObj = JSON.parse(attrs)
      if(attrObj){
        const descArr = attrObj.map(item=>{return item.value})
        if(descArr){
          attrDesc = descArr.join(',')
        }
      }
      return attrDesc
    },
  }


}
</script>

<style scoped lang="sass">
.wrap
  display: flex
  flex-direction: column
  min-height: 100vh
  background: url(~@/assets/images/pay/bg.png)
  background-position: top left
  background-size: 100vw 238px
  background-clip: border-box
  background-repeat: no-repeat


.col
  display: flex
  flex-direction: row
  justify-content: center
  align-items: center
  z-index: 99
  width: 100%


.status-wrap
  display: flex
  flex-direction: column
  justify-content: space-between
  align-items: center
  position: relative
  z-index: 1
  .status-bg
    position: absolute
    width: 100%
    height: 238px
    overflow-y: hidden
    bottom: 0
    left: 0
    z-index: -10
  .col
    color: #fff
    &.pay-info
      margin-top: 13px
      font-size: 13px
      color: #ffffff
      span
        margin-left: 15px
        display: flex
        align-items: center
      .van-count-down
        display: flex
        color: #ffffff
        font-size: 13px
        align-items: center
        font-weight: normal
        line-height: unset

    &.status
      margin-top: 27px
    &.pay-btn
      margin-top: 13px
      .van-button
        width: 100px
        border-radius: 18px
        color: #ff0000
        font-size: 14px
        font-weight: 400


.address-wrap
  z-index: 999
  margin-top: 25px
  border-radius: 5px
  background-color: #fff
  .van-cell
    background-color: transparent
  .title
    display: flex
    flex: 1
    flex-direction: row
    justify-content: space-between
  .address
    display: flex
    flex: 1
    flex-direction: column
    font-size: 14px
    .user, .address-detail
      display: flex
      flex: 1
    .user
      color: #333333
      span
        margin-right: 15px
    .address-detail
      color: #666666
      justify-content: flex-start
      align-items: flex-start
      .tag
        margin-right: 10px
      .detail
        display: flex
        flex: 1


.order-item-wrap
  display: flex
  flex: 1
  flex-direction: column
  background-color: #fff
  border-radius: 5px
  .order-merchant
    .card-wrap
      display: flex
      flex-direction: row
      justify-content: space-between
      .van-card
        background-color: #ffffff
        .van-card__header
          .van-card__content
            .van-card__title
              font-size: 14px
              line-height: 18px
            .van-card__desc
              font-size: 13px
              margin-top: 2px
              color: #999999
            .van-card__bottom
              .van-card__price
                color: #333333
                font-weight: 400
                .van-card__price-currency .van-card__price-decimal
                  font-size: 16px
                .van-card__price-integer
                  font-size: 18px
      .card-num
        color: #999999
        font-size: 13px
        display: flex
        justify-content: center
        align-items: center
        flex-direction: column
        margin-right: 16px

    .merchant
      font-size: 14px
      font-weight: 400
      color: #333333
      padding: 8px 16px 0 16px
      display: flex
      align-items: center
      .van-icon
        margin-right: 10px


.order-info-wrap
  display: flex
  flex-direction: column
  width: 100%
  border-radius: 10px
  padding: 20px 16px 16px 16px
  box-sizing: border-box
  color: #666666
  font-size: 14px
  background-color: #fff
  .item
    &.last-total
      justify-content: flex-end
      font-size: 14px
      font-weight: 400
      margin-bottom: 0
      .title
        color: #333333
      .price
        font-size: 18px
        color: #ff0000

    &.total
      justify-content: space-between
      .content
        color: #999999
        margin-left: unset
    width: 100%
    display: flex
    flex-direction: row
    align-items: center
    margin-bottom: 12px
    .content
      color: #333333
      margin-left: 23px

.bottom-pay
  display: flex
  width: 100%
  padding: 8px 13px
  box-sizing: border-box
  background-color: #fff
  justify-content: flex-end
  .van-button
    border-radius: 10px
    font-size: 14px


</style>

<style scoped>
/*.name-box {
  font-size: 14px;
  color: #333333;
}

.address-detail {
  font-size: 14px;
  color: #666666;
}

.top-box {
  position: relative;
}

.top-time-box {
  margin-top: -170px;
  height: 170px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 13px;
}

.wait-box {
  margin-top: 30px;
  display: flex;
  z-index: 10;
  align-items: center;
}

.wait-z {
  color: #FFFFFF;
  font-size: 16px;
  margin-left: 10px;
}

.time-box {
  margin-top: 10px;
  z-index: 10;
  color: #FFFFFF;
}

.pay-btn {
  margin-top: 10px;
  padding: 5px 12px;
  z-index: 10;
  font-size: 14px;
  color: #FF0000;
  background: linear-gradient(95.54deg, #FFF3F1 9.71%, #FFEAE8 113.08%);
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 18px;
}

.rectangle {
  position: absolute;
  bottom: 0px;
  width: 100%;
  background: #FFFFFF;
  height: 30px;
  border-radius: 10px 10px 0px 0px;
}

.address-box {
  padding: 0px 20px 20px 20px;
  background: #FFFFFF;
  border-radius: 0px 0px 10px 10px;
}

.product-box {
  border: 10px;
  margin-top: 10px;
  background: #FFFFFF;
  padding: 10px 20px;
}

.shop-box {
  display: flex;
  align-items: center;
}

.shop-name {
  font-size: 14px;
  color: #333333;
  margin-left: 10px;
}
.order-info-box{
  margin-top: 10px;
  padding: 10px 20px;
  background: #FFFFFF;
  border-radius: 10px;
  font-size: 14px;
  color: #666666;
  display: flex;
  flex-direction: column;
}
.order-info-box div{
}
.order-no-box{
  display: flex;
  align-items: center;
}
.line{
  border-bottom: 1px solid #EDEDED;
}
.total-box{
  display: flex;
  justify-content: space-between;
}
.pay-money{
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.money-title{
  font-size: 14px;
  color: #333333;
}
.money{
  font-size: 16px;
  color: #FF0000;
}
.bottom-box{
  width: 100%;
  height: 53px;
  background: #FFFFFF;
  position: fixed;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0px 20px;
  box-sizing: border-box;
}
.go-pay{
  font-size: 14px;
  color: #FFFFFF;
  background: #FF2020;
  padding: 10px 30px;
  border-radius: 10px;
}*/
</style>
